import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { createClient } from '@supabase/supabase-js';
import Header from '../components/Header/Header';
import TopItems from '../components/TopItems/TopItems';
import ItemsFeed from '../components/ItemsFeed/ItemsFeed';
import AIConsultant from '../components/AIConsultant/AIConsultant';

const AppContainer = styled.div`
  background-color: #F8F9FA;
  min-height: 100vh;
  padding-bottom: 80px;
  
  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 18px;
  color: var(--text-color);
`;

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL || 'https://zyeihguhsrkteuyylgbd.supabase.co',
  process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp5ZWloZ3Voc3JrdGV1eXlsZ2JkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDEwMzAyMjUsImV4cCI6MjA1NjYwNjIyNX0.XvOg55HUoB26rJ3EktuRl_8IYws4svyxiz8i3yARUVI'
);

function DatabaseVersion({ onItemClick }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchItems() {
      try {
        console.log('Fetching items from Supabase...');
        const { data, error } = await supabase
          .from('items')
          .select('*')
          .eq('available', true);

        if (error) {
          console.error('Supabase error:', error);
          throw error;
        }

        console.log('Fetched items:', data);

        // Transform data to match the expected format
        const transformedItems = data.map(item => ({
          id: item.id,
          title: item.model || 'No title',
          price: item.price,
          old_price: item.old_price,
          image_url: item.picture,
          category: item.category || 'Uncategorized',
          description: item.description || '',
          vendor: item.vendor,
          vendor_code: item.vendor_code,
          params: item.param
        }));

        setItems(transformedItems);
      } catch (error) {
        console.error('Error fetching items:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchItems();
  }, []);

  if (loading) {
    return <LoadingContainer>Loading products...</LoadingContainer>;
  }

  if (error) {
    return <LoadingContainer>Error: {error}</LoadingContainer>;
  }

  // Get top items (most expensive ones)
  const topItems = [...items] // Create a copy before sorting
    .sort((a, b) => b.price - a.price)
    .slice(0, 6);

  return (
    <AppContainer>
      <Header />
      <TopItems items={topItems} onItemClick={onItemClick} />
      <ItemsFeed items={items} onItemClick={onItemClick} />
      <AIConsultant />
    </AppContainer>
  );
}

export default DatabaseVersion; 