import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import GlobalStyles from './styles/GlobalStyles';

// Cleanup any existing Chatbase elements
const cleanup = () => {
  // Remove scripts
  document.querySelectorAll('script[src*="chatbase"]').forEach(el => el.remove());
  document.querySelectorAll('script[id*="chatbase"]').forEach(el => el.remove());
  
  // Remove iframes
  document.querySelectorAll('iframe[src*="chatbase"]').forEach(el => el.remove());
  
  // Remove other elements
  document.querySelectorAll('[class*="chatbase"]').forEach(el => el.remove());
  document.querySelectorAll('[id*="chatbase"]').forEach(el => el.remove());
  
  // Clear any chatbase data from window
  if (window.chatbase) {
    delete window.chatbase;
  }
  if (window.ChatbaseConfig) {
    delete window.ChatbaseConfig;
  }
};

cleanup();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>
); 