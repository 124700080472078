import React, { useEffect } from 'react';

function AIConsultant() {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(){
        if(!window.chatbase){
          window.chatbase=function(){
            window.chatbase.q.push(arguments)
          };
          window.chatbase.q=[];
        }
        var s=document.createElement("script");
        s.async=true;
        s.src="https://www.chatbase.co/embed.min.js";
        s.id="m_odJZIoQmwhwpPk8Fpk2";
        document.head.appendChild(s);
      })();
    `;
    document.body.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
      const chatbaseScript = document.getElementById('m_odJZIoQmwhwpPk8Fpk2');
      if (chatbaseScript) {
        chatbaseScript.remove();
      }
    };
  }, []);

  return null;
}

export default AIConsultant; 