import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--shadow);
  transition: transform 0.2s;
  text-decoration: none;
  color: inherit;
  width: 180px;
  flex-shrink: 0;
  padding: 12px;
  
  &:hover {
    transform: translateY(-4px);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 180px;
  background: #eee;
  border-radius: var(--border-radius);
  margin-bottom: 12px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 8px 0;
`;

const Title = styled.h3`
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--text-color);
`;

const Price = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
`;

const AddButton = styled.button`
  width: 100%;
  background: var(--primary-color);
  color: white;
  padding: 8px;
  border-radius: var(--border-radius);
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: opacity 0.2s;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

function ProductCard({ id, title, price, imageUrl }) {
  return (
    <Card to={`/product/${id}`}>
      <ImageContainer>
        <Image src={imageUrl} alt={title} />
      </ImageContainer>
      <Content>
        <Title>{title}</Title>
        <Price>${price}</Price>
        <AddButton>
          <FaShoppingCart size={14} />
          Add to Cart
        </AddButton>
      </Content>
    </Card>
  );
}

export default ProductCard; 