import React from 'react';
import styled from 'styled-components';
import { FaUser } from 'react-icons/fa';

const HeaderContainer = styled.header`
  padding: 16px;
  background: white;
  box-shadow: var(--shadow);
  position: sticky;
  top: 0;
  z-index: 100;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled.h1`
  font-size: 24px;
  color: var(--primary-color);
`;

const ProfileButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Banner = styled.div`
  width: 100%;
  height: 200px;
  background: #ddd;
  margin-top: 16px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
`;

function Header() {
  return (
    <HeaderContainer>
      <HeaderContent>
        <Logo>ShopAI</Logo>
        <ProfileButton>
          <FaUser />
        </ProfileButton>
      </HeaderContent>
      <Banner>Promotional Banner Placeholder</Banner>
    </HeaderContainer>
  );
}

export default Header; 