import React from 'react';
import styled from 'styled-components';
import ProductCard from '../ProductCard/ProductCard';

const Section = styled.section`
  padding: 24px 16px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
  color: var(--text-color);
`;

const Carousel = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding: 4px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

function TopItems({ items = [] }) {
  return (
    <Section>
      <Title>Top Items</Title>
      <Carousel>
        {items.map(item => (
          <ProductCard
            key={item.id}
            id={item.id}
            title={item.title}
            price={item.price}
            imageUrl={item.image_url}
          />
        ))}
      </Carousel>
    </Section>
  );
}

export default TopItems; 