import React, { useState } from 'react';
import styled from 'styled-components';
import ProductCard from '../ProductCard/ProductCard';

const Section = styled.section`
  padding: 24px 16px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
  color: var(--text-color);
`;

const CategoryFilters = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  overflow-x: auto;
  padding-bottom: 8px;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CategoryButton = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  background: ${props => props.active ? 'var(--primary-color)' : 'white'};
  color: ${props => props.active ? 'white' : 'var(--text-color)'};
  box-shadow: var(--shadow);
  font-size: 14px;
  white-space: nowrap;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  max-width: 1200px;
  margin: 0 auto;
`;

function ItemsFeed({ items = [], onItemClick }) {
  const [activeCategory, setActiveCategory] = useState('All');

  // Get unique categories from items
  const categories = ['All', ...new Set(items.map(item => item.category).filter(Boolean))];

  const filteredItems = activeCategory === 'All' 
    ? items 
    : items.filter(item => item.category === activeCategory);

  return (
    <Section>
      <Title>All Products</Title>
      <CategoryFilters>
        {categories.map(category => (
          <CategoryButton
            key={category}
            active={activeCategory === category}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </CategoryButton>
        ))}
      </CategoryFilters>
      <Grid>
        {filteredItems.map(item => (
          <ProductCard
            key={item.id}
            id={item.id}
            title={item.title}
            price={item.price}
            imageUrl={item.image_url}
            onClick={() => onItemClick(item)}
          />
        ))}
      </Grid>
    </Section>
  );
}

export default ItemsFeed; 