import React from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL || 'https://zyeihguhsrkteuyylgbd.supabase.co',
  process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp5ZWloZ3Voc3JrdGV1eXlsZ2JkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDEwMzAyMjUsImV4cCI6MjA1NjYwNjIyNX0.XvOg55HUoB26rJ3EktuRl_8IYws4svyxiz8i3yARUVI'
);

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  margin-bottom: 20px;
  border: none;
  background: none;
  color: var(--text-color);
  cursor: pointer;
  
  &:hover {
    color: var(--primary-color);
  }
`;

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageContainer = styled.div`
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: var(--shadow);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: var(--text-color);
`;

const Price = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-color);
`;

const Description = styled.p`
  color: var(--text-color);
  line-height: 1.6;
`;

const Params = styled.div`
  display: grid;
  gap: 8px;
`;

const ParamItem = styled.div`
  display: flex;
  gap: 8px;
  
  span:first-child {
    font-weight: bold;
  }
`;

function ProductPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchProduct() {
      try {
        const { data, error } = await supabase
          .from('items')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        setProduct(data);
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchProduct();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <Container>
      <BackButton onClick={() => navigate('/')}>
        <FaArrowLeft /> Back to products
      </BackButton>
      
      <ProductContainer>
        <ImageContainer>
          <Image src={product.picture} alt={product.model} />
        </ImageContainer>
        
        <Details>
          <Title>{product.model}</Title>
          <Price>${product.price}</Price>
          {product.old_price && (
            <span style={{ textDecoration: 'line-through', color: 'gray' }}>
              ${product.old_price}
            </span>
          )}
          <Description>{product.description}</Description>
          
          <Params>
            {product.vendor && (
              <ParamItem>
                <span>Vendor:</span>
                <span>{product.vendor}</span>
              </ParamItem>
            )}
            {product.vendor_code && (
              <ParamItem>
                <span>Vendor Code:</span>
                <span>{product.vendor_code}</span>
              </ParamItem>
            )}
            {product.param && product.param.map((param, index) => (
              <ParamItem key={index}>
                <span>{param.name}:</span>
                <span>{param.value}</span>
              </ParamItem>
            ))}
          </Params>
        </Details>
      </ProductContainer>
    </Container>
  );
}

export default ProductPage; 