import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import DatabaseVersion from './pages/DatabaseVersion';
import ProductPage from './pages/ProductPage';

const AppContainer = styled.div`
  --primary-color: #0088CC;
  --text-color: #333333;
  --shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

function App() {
  const handleItemClick = (item) => {
    if (item) {
      console.log('Selected item:', item);
    }
  };

  return (
    <BrowserRouter>
      <GlobalStyles />
      <AppContainer>
        <Routes>
          <Route path="/" element={<DatabaseVersion onItemClick={handleItemClick} />} />
          <Route path="/product/:id" element={<ProductPage />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
}

export default App; 